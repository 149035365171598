import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AzureAdService } from 'src/app/services/azure-ad.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../logout/logout.component';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment.qa';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public year: number = new Date().getFullYear();
  public userLogged: any;

  // Observa si el dispositivo en que estamos es mobile
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog, // private azureAd: AzureAdService
    private userService: UserService
  ) {
    const user = this.userService.getSessionData();
    if (user !== undefined) {
      this.userLogged = user;
    } else {
      this.userLogged = null;
    }
  }

  ngOnInit(): void {}
  public logOut(): void {
    const dialogRef = this.dialog.open(LogoutComponent, {
      width: '600px',
      // data: application
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Confirm') {
        //   this.deleteApplication(application);
        // Borro el usuario
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('urlParam');
        sessionStorage.removeItem('loginPass');
        sessionStorage.removeItem(environment.LOGINPASS_LBL);
        this.userService.deleteSessionData();
        // Borro las cookies
        this.deleteCookies();
        // reenvío al login
        this.router.navigate(['/']);
      }
    });
  }

  private deleteCookies(): void {
    const cookies = document.cookie.split(';');
    if (cookies.length === 0) {
      console.warn('No se borro la cookie por que no existe');
      return;
    }
    document.cookie = `ST=; max-age=0`;
    document.cookie = `HT=; max-age=0`;
    document.cookie = `PT=; max-age=0`;
    document.cookie = `ACC=; max-age=0`;
    document.cookie = `NUMUSER=; max-age=0`;
    document.cookie = `NAME=; max-age=0`;
    document.cookie = `ST2=; max-age=0`;
    document.cookie = `HT2=; max-age=0`;
    document.cookie = `PT2=; max-age=0`;
    document.cookie = `ACC2=; max-age=0`;
    document.cookie = `NUMUSER2=; max-age=0`;
    document.cookie = `NAME2=; max-age=0`;
  }
}
