
<div class="container-welcome mtop-module">
  <span class="welcome-text-panel">Bienvenido(a) {{ userLogged.name }} </span>
</div>

<div id="contenido" appBlockCopyPaste class="container mtop-module">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title appBlockCopyPaste> Beneficios </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mtop">
        <ul class="product-icon-list">
          <li *ngFor="let item of apps">
            <a [href]="item.url" [ngClass]="{ disabled: !item.active }">
              <mat-icon>{{ item.icon }}</mat-icon>
              <p>
                {{ item.name }}
              </p>
            </a>
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

