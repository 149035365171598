import { FirebaseEnv } from "./firebase.qa";
    const apiGiftCardEndPoint= '';
    const pssDpt = '<jA:ZDk6^Zbe^ew%';
    const pssDpt2 = 'YdEgR_jK:903TV^JfG7^O%<';
    const USER_LBL = '53616c7465645f5f08c9e9722c6a0a0653e7679a822147b3124c8adc441d6cb1';
    const TOKEN_LBL = '53616c7465645f5f23b6875c79af9b268d6d768b5477b02d15aaee59185354cb';
    const URLPARAM_LBL = '53616c7465645f5f95207574f995ae3dc0b782383fa168f275b6b4ff5ed9b6de';
    const LOGINPASS_LBL = '53616c7465645f5f00ad2f7051a20b92580a1e8684907c6d15b5ee2efcdbc82c';
    const disableLogin = false;
    //prod
    const apiProfileEndPoint= 'https://services.beneficios.falabella.com/profile/'; //otiginal
    const personnelUrl=     'https://portal.beneficios.falabella.com/';            //original
    const redirectWebMsal=  'https://portal.beneficios.falabella.com/';            //original
    const giftcardUrl ='https://giftcard.beneficios.falabella.com';
    const ticketsUrl = 'https://entradas.beneficios.falabella.com/';
    const profileUrl = 'https://perfiles.beneficios.falabella.com';

    //qa
    // const apiProfileEndPoint= 'http://bien-gye-uat.falabella.com/profile/'; //otiginal
    // const personnelUrl=     'http://qa-portal-web-personal.web.app/';            //original
    // const redirectWebMsal=  'http://qa-portal-web-personal.web.app/';            //original
    // const giftcardUrl = 'http://qa-esup-hrmg-bien-gif.web.app';
    // const ticketsUrl = 'http://qa-esup-hrmg-bien-gye.web.app/';
    // const profileUrl = 'http://qa-esup-hrmg-bien-gyu.web.app';

    //local
    // const giftcardUrl = 'http://localhost:4500';
    // const ticketsUrl = 'http://localhost:4400';
    // const profileUrl = 'http://localhost:4300';
    // const apiProfileEndPoint= 'http://localhost:3000/profile/'; //test
    // const redirectWebMsal= 'http://localhost:4200/'; //test
    // const personnelUrl = redirectWebMsal;

    //docker
    // const giftcardUrl = 'http://localhost:4500';
    // const ticketsUrl = 'http://localhost:4400';
    // const profileUrl = 'http://localhost:4300';
    // const apiProfileEndPoint= 'http://172.17.0.3:3000/profile/'; //test
    // const redirectWebMsal= 'http://localhost:4200/'; //test
    // const personnelUrl = redirectWebMsal;

export const environment : FirebaseEnv = {
    production: false,
    //** CONFIGURACION QA *//
    // firebase: {
    //     apiKey: 'AIzaSyAkRRHw3XaoF4MyhsmLQa-4wz_pk-Yyfd0',
    //     authDomain: 'qa-portal-web-personal.firebaseapp.com',
    //     projectId: 'qa-portal-web-personal',
    //     storageBucket: 'qa-portal-web-personal.appspot.com',
    //     messagingSenderId: '1020370672034',
    //     appId: '1:1020370672034:web:403e1d2bf7cca3c4c6fafb'
    // },

    //** CONFIGURACION PRODUCCION *//
    firebase: {
        apiKey: "AIzaSyCD9GA5qhXSJyJxvrp1urtHjnRTPwQvspw",
        authDomain: "portal-web-personal.firebaseapp.com",
        projectId: "portal-web-personal",
        storageBucket: "portal-web-personal.appspot.com",
        messagingSenderId: "407615671283",
        appId: "1:407615671283:web:53f98d6626eb0b3004a086"
    },

    redirectWebMsal,
    giftcardUrl,
    personnelUrl,
    ticketsUrl,
    profileUrl,
    apiProfileEndPoint,
    pssDpt,
    pssDpt2,
    USER_LBL,
    TOKEN_LBL,
    URLPARAM_LBL,
    LOGINPASS_LBL,
    disableLogin
};
