import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.scss'],
})
export class ExitComponent implements OnInit {
  constructor(private router: Router, private userService: UserService) {}

  ngOnInit(): void {
    // Borro el usuario
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('urlParam');
    this.userService.deleteSessionData();
    // Borro las cookies
    this.deleteCookies();
    // reenvío al login
    this.router.navigate(['/']);
  }

  private deleteCookies(): void {
    const cookies = document.cookie.split(';');
    if (cookies.length === 0) {
      console.warn('No se borro la cookie por que no existe');
      return;
    }
    document.cookie = `ST=; max-age=0`;
    document.cookie = `HT=; max-age=0`;
    document.cookie = `PT=; max-age=0`;
    document.cookie = `ACC=; max-age=0`;
    document.cookie = `NUMUSER=; max-age=0`;
    document.cookie = `NAME=; max-age=0`;
    document.cookie = `ST2=; max-age=0`;
    document.cookie = `HT2=; max-age=0`;
    document.cookie = `PT2=; max-age=0`;
    document.cookie = `ACC2=; max-age=0`;
    document.cookie = `NUMUSER2=; max-age=0`;
    document.cookie = `NAME2=; max-age=0`;
  }
}
