import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyGroupInterface } from 'src/app/interfaces/company-group.interface';
import { CompanyInterface } from 'src/app/interfaces/company.interface';
import { PermissionInterface } from 'src/app/interfaces/permission.interface';
import { ResponseInterface } from 'src/app/interfaces/response.interface';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user.service';
import { LoginService } from '../../services/login.service';
import { EncryptService } from '../../services/encrypt.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.qa';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public year: number;
  public loading: boolean;
  // public unavailable = false; //original
  public unavailable;
  public loginForm: FormGroup;
  public user: FormControl;
  public pass: FormControl;
  private personnelPortalUrl = `${environment.redirectWebMsal}/`;
  public disableLogin = environment.disableLogin;

  constructor(
    private router: Router,
    // private azureAd: AzureAdService,
    private userService: UserService,
    // nuevo yko
    private login: LoginService,
    private encrypt: EncryptService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {
    this.loading = false;

    this.activatedRoute.paramMap.subscribe(params => {
      // if( !this.disableLogin){ //original
      if( this.disableLogin ){
        this.hideLogin(true);
        if (params.get('unavailable') == 'true'){
          this.hideLogin(false);
        }
      }
      else{
        console.log('else');
        // this.hideLogin(params.get('unavailable'));
        this.showLogin('false');

      }

    });
  }

  ngOnInit() {
    this.user = new FormControl('', [Validators.required]);
    this.pass = new FormControl('', [Validators.required]);

    const d = new Date();
    this.year = d.getFullYear();
    if (document.cookie.split(';').length > 1) {
      this.presistenceAuth();
    }



  }
  //nuevo login

  public showLogin(param){
    if (param == 'false'){
      // console.log('showLogin', param);
      this.unavailable = false;
    }
    else{
      this.unavailable = true;
    }

  }
  public hideLogin(param){
    this.unavailable = param;
  }
  //nuevo login para giftcard, perfiles, tickets

  public reverseString(str) {
    var splitString = str.split('');
    // ["h", "e", "l", "l", "o"]
    var reverseArray = splitString.reverse();
    // ["o", "l", "l", "e", "h"]
    var joinArray = reverseArray.join('');
    // "olleh"
    // console.log('reverseString ->', joinArray);

    return joinArray;
  }
  // ./ nuevo login para giftcard, perfiles, tickets

  public logIn(): void {
    this.loading = true;

    if (
      this.user.value !== undefined &&
      this.user.value !== null &&
      this.pass.value !== undefined &&
      this.pass.value !== null
    ) {
      const paramUser = this.user.value;
      const paramPass = this.pass.value;

      let limit = Date.now() + 900000; //limite de 1 min hacia adelante // se modifica a 15 min
      const limitSecret = this.reverseString(String(limit));
      let objectToGo = { user: paramUser, pass: paramPass, param: limitSecret };
      let objToCrypt = { user: paramUser, pass: paramPass, param: limitSecret };
      let newObjectToGo = { version: '' };
      const encriptado2 = this.encrypt.encryptData(
        JSON.stringify(objectToGo),
        limitSecret
      );
      const securitySecret = environment.pssDpt;
      const primerCrypt = this.encrypt.encryptData(
        JSON.stringify(objToCrypt),
        securitySecret
      );

      const securitySecret2 = environment.pssDpt2;
      const segundoCrypt = this.encrypt.encryptData(
        JSON.stringify(primerCrypt),
        securitySecret2
      );

      objectToGo = { user: limitSecret, pass: encriptado2, param: '' };
      newObjectToGo = { version: segundoCrypt };

      this.login.getUserDataLogin(newObjectToGo).subscribe(
        (data: any) => {

          //decripto la data
          // data.data = JSON.parse(this.encrypt.decryptData(data.data, securitySecret))
          // console.log('DATA decrypt->', JSON.parse( JSON.stringify(data.data)));
          // console.log("DATA: ", data);
          //valido data
          if (data.data === undefined || data.data === 'NO EXISTE|') {
            this.toastr.error('Usuario/contraseña inválido o sin permisos');
            this.loading = false;
            return;
          }

          if(data.data === undefined || data.data === 'CLAVE VENCIDA|' ){
            this.toastr.error('Clave vencida');
            this.loading = false;
            return;
          }

          if(data.data === undefined || data.data === 'ACCESO DENEGADO|' ){
            this.toastr.error('Usuario bloqueado/inactivo');
            this.loading = false;
            return;
          }

          if (data.data === undefined || data.data === 'NO EXISTE') {
            this.toastr.error('Usuario/contraseña inválido o sin permisos');
            this.loading = false;
            return;
          }

          if(data.data === undefined || data.data === 'CLAVE VENCIDA' ){
            this.toastr.error('Clave vencida');
            this.loading = false;
            return;
          }

          if(data.data === undefined || data.data === 'ACCESO DENEGADO' ){
            this.toastr.error('Usuario bloqueado/inactivo');
            this.loading = false;
            return;
          }

          if(data.data === undefined || data.data === 'BENEFICIO VIGENTE SOLO PARA COLABORADORES CON CONTRATO INDEFINIDO' ){
            this.toastr.error('BENEFICIO VIGENTE SOLO PARA COLABORADORES CON CONTRATO INDEFINIDO');
            this.loading = false;
            return;
          }

          //decripto la data
          // data.data = JSON.parse(this.encrypt.decryptData(data.data, securitySecret)); //original

          //valido si el cuerpo de la respuesta esta cifrada o no
          if(!data.data.hasOwnProperty('name')){
            data.data = JSON.parse(this.encrypt.decryptData(data.data, securitySecret)); //original
          }
          // console.log('DATA decrypt->', JSON.parse( JSON.stringify(data.data)));





          const user: UserInterface = { ...data.data };
          user.companyGroup = data.groups;

          //sessionStorage.setItem('user', JSON.stringify(user));
          this.userService.setSessionData(user);

          //sessionStorage.setItem('token', JSON.stringify(data.data.idToken));
          this.userService.setTokenData(data.data.idToken);
          //sessionStorage.setItem('urlParam', JSON.stringify(newObjectToGo));
          this.userService.setUrlParamData(newObjectToGo);

          this.genCookieCrossWeb(data.data.idToken, data.data.email, user);

          this.userService.getDocumentByNumUser(user.numUser).subscribe(
            (res: ResponseInterface) => {
              // 20210915 decripto la data
              res.data = JSON.parse(this.encrypt.decryptData(res.data, securitySecret));

              if (
                res.data !== undefined &&
                res.data !== null &&
                res.data.length > 0
              ) {
                user.permissions = this.getMyPermissions(res.data);
                user.companyGroup = this.getMyGroup(res.data);
                user.company = this.getMyCompany(res.data);
                user.refId = res.data[0].refId;
                // Pisamos la información anterior agregando la empresa y los permisos
                //sessionStorage.setItem('user', JSON.stringify(user));
                this.userService.setSessionData(user);
                this.loading = false;
                this.router.navigate(['/home']); //original
              } else {
                //se agrega nuevo flujo para usuarios sin registros en firebase
                this.router.navigate(['/home']);
                this.loading = false;
              }
            },
            (err) => {
              // console.log('getDocumentByNumUser error', err);
              if (err.status === 401) {
                this.loading = false;
                // this.toastr.error('Usuario/contraseña inválido o sin permisos');
                this.toastr
                  .info('Usuario/contraseña inválido o sin permisos', '', {
                    progressBar: true,
                    timeOut: 5000,
                  })
                  .onHidden.subscribe(() => {
                    // Si cierra el mensaje o se terina el tiempo, redirecciona al portal
                    window.location.href = this.personnelPortalUrl;
                  });
              }

              console.error(err);
              this.loading = false;
            }
          );

          // this.loading = false;
        },
        (err) => {
          console.error(err);
          this.loading = false;
        }
      );
    }
  }


  public presistenceAuth(): void {
    this.loading = true;
    const user: UserInterface = this.genUserFromCookiesCrossWeb();
    // Guardamos la primera parte de la información ya que tenemos el token
    // sessionStorage.setItem('user', JSON.stringify(user));
    this.userService.setSessionData(user);

    /************************************************************************
     ** Antes de buscar los datos del usuario en la app de perfilamiento
     ** deberiamos tener el numUser desde el servicio de Empleado Central o
     ** el viejo sistema de falabella.
     *************************************************************************
     */

    // Obtengo los permisos del usuario en perfilamiento
    // this.userService.getDocumentByEmail(user.email).subscribe(
    this.userService.getDocumentByNumUser(user.numUser).subscribe(
      (res: ResponseInterface) => {
        // 20210915 decripto la data
        res.data = JSON.parse(this.encrypt.decryptData(res.data, environment.pssDpt));

        if (res.data.length > 0) {
          user.permissions = this.getMyPermissions(res.data);
          user.companyGroup = this.getMyGroup(res.data);
          user.company = this.getMyCompany(res.data);
          user.name = res.data[0].name;
          user.refId = res.data[0].refId;
          // Pisamos la información anterior agregando la empresa y los permisos
          // sessionStorage.setItem('user', JSON.stringify(user));
          this.userService.setSessionData(user);
          this.loading = false;
          this.router.navigate(['/home']);
        } else {
          this.loading = false;
          this.router.navigate(['/home']);
        }
      },
      (err) => {
        console.error(err);
        this.loading = false;
      }
    );
  }

  private getMyPermissions(user): Array<PermissionInterface> {
    const permissions = new Array<PermissionInterface>();

    user.find((userFind) => {
      // Busco los grupos del usuario
      userFind.groups.forEach((group) => {
        if (group.active === true) {
          // Busco las compañías activas
          let roles = new Array();
          group.companies.forEach(companyActive => {
            if (companyActive.active === true) {
              roles.push(companyActive.roles)
            }
          });
          let role = new Array();
          roles.forEach(rol => {
            for (let index = 0; index < rol.length; index++) {
              if (rol[index].application.code.toUpperCase().includes('PROFILE') && rol[index].active
                && rol[index].selected) {
                role.push(rol[index])
              }
            }
          });

          if(role.length === 0){
            return permissions;
          }
          else{
            role[0].permissions.forEach((permission) => {
              const perm: PermissionInterface = {
                code: permission.code,
                description: permission.description,
                active: permission.active,
                createdAt: permission.createdAt,
                refId: permission.refId,
              };
              permissions.push(perm);
            });
          }

        }
      });
    });
    return permissions;
  }

  private getMyCompany(data): CompanyInterface {
    const company: CompanyInterface = {
      name: '',
      code: '',
      active: true,
      createdAt: new Date(),
      refId: '',
    };
    data.find((user) => {
      user.groups.forEach((group) => {
        // Busco la compañía activa
        const companyFinded = group.companies.find(
          (company) => company.active === true
        );
        company.code = companyFinded.code;
        company.name = companyFinded.name;
        company.active = companyFinded.active;
        company.createdAt = companyFinded.createdAt;
        company.refId = companyFinded.refId;
      });
    });

    return company;
  }
  private getMyGroup(data): CompanyGroupInterface {
    const group: CompanyGroupInterface = {
      code: '',
      name: '',
      active: true,
      createdAt: new Date(),
      refId: '',
    };
    data.find((user) => {
      user.groups
        .filter((f) => f.active === true)
        .forEach((gru) => {
          group.code = gru.code;
          group.name = gru.name;
          group.active = gru.active;
          group.createdAt = gru.createdAt;
          group.refId = gru.refId;
        });
    });
    return group;
  }
  /**
   * @author Rodrigo Rojas S.
   * @description Genera cookies que serán leídas desde
   * las otras aplicaciones para garantizar que es un
   * usuario correcto y logeado desde personal
   * @param token String
   * @param userEmail String
   */
  private genCookieCrossWeb(
    token: string,
    userEmail: string,
    userData = null
  ): void {
    // console.log('creando cookies');
    const arrayToken = token.split('.');
    const HT = arrayToken[0];
    const PT = arrayToken[1];
    const ST = arrayToken[2];
    const numUser = userData === null ? '' : userData.numUser;
    const rutUser = userData.rut;
    // let nameUser = (!userData && !userData.name)? '':userData.name ; // si NO llegaron a pasar el usuario, lo cambio a blanco para que no muera el stringify
    let nameUser = userData && userData.name ? userData.name : ''; // si NO llegaron a pasar el usuario, lo cambio a blanco para que no muera el stringify
    // console.log('nameUser cokie personal-->', nameUser);

    //personel
    document.cookie = `ST=${ST}; max-age=3600;path=/`;
    document.cookie = `HT=${HT}; max-age=3600;path=/`;
    document.cookie = `PT=${PT}; max-age=3600;path=/`;
    document.cookie = `ACC=${userEmail}; max-age=3600;path=/`;
    document.cookie = `NUMUSER=${numUser}; max-age=3600;path=/`;
    document.cookie = `NAME=${nameUser}; max-age=3600;path=/`;

  }

  /**
   * @author Rodrigo Rojas S.
   * @description Obtengo la información almacenada en las cookies y creo
   * una interfaz de usuario con lo básico
   * @returns UserInterface
   */
  private genUserFromCookiesCrossWeb(): UserInterface {
    const cookies = document.cookie.split(';');
    // console.log('lectura cookies personal', cookies);
    if (cookies.length <= 1) {
      this.toastr
        .info(
          'No se ha podido obtener un usuario identificado, serás redireccionado(a) a personal.falabella',
          '',
          {
            progressBar: true,
            timeOut: 5000,
          }
        )
        .onHidden.subscribe(() => {
          // Si cierra el mensaje o se terina el tiempo, redirecciona al portal
          window.location.href = this.personnelPortalUrl;
        });
      return;
    }
    let cookEmail = '';
    let ht = '';
    let st = '';
    let pt = '';
    let numUser = '';
    let nameUser = '';
    cookies.forEach((i) => {
      const iArr = i.split('=');
      const k = iArr[0].trim();
      const v = iArr[1].trim();

      switch (k) {
        case 'ACC':
          cookEmail = v.slice();
          break;
        case 'HT':
          ht = v.slice();
          break;
        case 'ST':
          st = v.slice();
          break;
        case 'PT':
          pt = v.slice();
          break;
        case 'NUMUSER':
          numUser = v.slice();
          break;
        case 'NAME':
          nameUser = v.slice();
          break;
        default:
          break;
      }
    });
    // Guardamos el token de la nueva manera
    const token = `${ht}.${pt}.${st}`;
    const user: UserInterface = {
      name: nameUser,
      email: cookEmail,
      numUser: numUser, // Obtener desde otro serv
      rut: numUser, // Obtener desde otro serv
      company: null,
      companyGroup: null,
      permissions: null,
      active: true,
      createdAt: new Date(),
      keyId: token,
      refId: null,
    };
    return user;
  }
}
