<div class="block">
  <div *ngIf="!unavailable" class="login-card">
    <div class="text-center">
      <img src="assets/img/falabellaLogo.png" alt="logo falabella" />
    </div>
    <div  class="login-container">
      <!-- <form [formGroup]="loginForm" autocomplete="false"> -->
      <form autocomplete="false">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Usuario</mat-label>
          <!-- <input matInput type="text" placeholder="correo@falabella.com" [formControl]="user" required> -->
          <input
            matInput
            type="text"
            placeholder="12345678k"
            [formControl]="user"
            required
          />
          <!-- <mat-error *ngIf="loginForm.controls.user.hasError('required')"> -->
          <mat-error *ngIf="!user.hasError('required')">
            Campo requerido
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Contraseña</mat-label>
          <input
            matInput
            type="password"
            placeholder="**********"
            [formControl]="pass"
            required
          />
          <!-- <input matInput type="password" placeholder="**********"  required> -->
          <mat-error *ngIf="!pass.hasError('required')">
            Campo requerido
          </mat-error>
        </mat-form-field>

        <!-- <mat-form-field class="full-width" *ngIf="selectedType === 'NUMDOCU'">
                    <mat-label>Número de Usuario</mat-label>
                    <input matInput type="text" name="numUser" formControlName="numUser"
                      placeholder="Rut sin dígito verificador" (change)="typeNumUser()" />
                    <mat-error *ngIf="adminForm.controls.numUser.hasError('required')">
                      Campo requerido
                    </mat-error>
                </mat-form-field> -->
      </form>
    </div>
    <button
      (click)="logIn()"
      color="primary"
      mat-raised-button
      class="full-width"
      [disabled]="
        loading || pass.hasError('required') || user.hasError('required')
      "
    >
      <mat-spinner
        *ngIf="loading"
        style="text-align: text-center"
        [diameter]="15"
        color="accent"
      ></mat-spinner>
      <span *ngIf="!loading">Acceder a Personal</span>
    </button>
    <!-- <mat-spinner class="loadingSpinner" diameter="30" *ngIf="loading"></mat-spinner> -->
    <div class="text-center mtop">
      <small>© Falabella Tecnología Corporativa | {{ year }}</small>
    </div>

    <!-- Original -->
    <!-- <button (click)="logIn()"color="primary" mat-raised-button class="full-width" [disabled]="loading">
            <mat-spinner *ngIf="loading" style="text-align: text-center;" [diameter]="15" color="accent"></mat-spinner>
            <span *ngIf="!loading">Acceder a Personal</span>
        </button>
        <div class="text-center mtop">
            <small>© Falabella Tecnología Corporativa | {{ year }}</small>
        </div> -->
  </div>
  <!-- PARA PASOS A PROD -->
  <!-- <input type="hidden" class="state" value="unavailable"/> -->
  <!-- <div class="login-card-unavailable"> -->
  <div *ngIf="unavailable" class="login-card">
    <div class="text-center">
      <img src="assets/img/falabellaLogo.png" alt="logo falabella" />
    </div>
    <div  class="login-container text-center" style="font-size: 20px;">
      Servicio no disponible temporalmente.<br>
      Estaremos de vuelta en unas horas.<br>
      Disculpe las molestias.<br>
    </div>
    
    <!-- <mat-spinner class="loadingSpinner" diameter="30" *ngIf="loading"></mat-spinner> -->
    <div class="text-center mtop">
      <small>© Falabella Tecnología Corporativa | {{ year }}</small>
    </div>

    <!-- Original -->
    <!-- <button (click)="logIn()"color="primary" mat-raised-button class="full-width" [disabled]="loading">
            <mat-spinner *ngIf="loading" style="text-align: text-center;" [diameter]="15" color="accent"></mat-spinner>
            <span *ngIf="!loading">Acceder a Personal</span>
        </button>
        <div class="text-center mtop">
            <small>© Falabella Tecnología Corporativa | {{ year }}</small>
        </div> -->
  </div>
</div>
