import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.qa';
import { EncryptService } from './encrypt.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  // Endpoint from CommentType
  private apiUrl = `${environment.apiProfileEndPoint}userbyemail`;
  private apiUrlByNumUser = `${environment.apiProfileEndPoint}userbynumuser`;

  constructor(private http: HttpClient, private encrypt: EncryptService) { }

  getDocumentByEmail(email: string) {
    const opts = this.headerParams();
    return this.http.get(`${this.apiUrl}/${email}`, opts).pipe(map(res => res));
  }
  
  getDocumentByNumUser(user: string) {
    const opts = this.headerParams();
    let encrypt = this.encrypt.encryptData(JSON.stringify(user), environment.pssDpt);
    return this.http.get(`${this.apiUrlByNumUser}/${encrypt}`, opts).pipe(map(res => res)); 
    // return this.http.get(`${this.apiUrlByNumUser}/${user}`, opts).pipe(map(res => res)); //original
  }
      

  headerParams() {
    // const userLogged = JSON.parse(sessionStorage.getItem('user'));
    const userLogged = this.getSessionData();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userLogged.keyId}`
      })
    };

    return httpOptions;
  }

  setSessionData(user: any) {
    const data = this.encrypt.encryptData(JSON.stringify(user), environment.pssDpt);
    sessionStorage.setItem(environment.USER_LBL, data);
  }

  getSessionData() {
    const data = sessionStorage.getItem(environment.USER_LBL);
    return JSON.parse(this.encrypt.decryptData(data, environment.pssDpt));
  }

  deleteSessionData() {
    sessionStorage.removeItem(environment.USER_LBL);
    sessionStorage.removeItem(environment.TOKEN_LBL);
    sessionStorage.removeItem(environment.URLPARAM_LBL);
  }

  setTokenData(token: any) {
    const data = this.encrypt.encryptData(environment.TOKEN_LBL, token);
    sessionStorage.setItem(environment.TOKEN_LBL, JSON.stringify(data));
  }

  getTokenData() {
    const data = sessionStorage.getItem(environment.TOKEN_LBL);
    return JSON.parse(this.encrypt.decryptData(data, environment.pssDpt));
  }

  setUrlParamData(urlParam: any) {
    const data = JSON.stringify(urlParam);
    sessionStorage.setItem(environment.URLPARAM_LBL, data);
  }

  getUrlParamData() {
    const data = sessionStorage.getItem(environment.URLPARAM_LBL);
    return JSON.parse(data);
  }

  setLoginPassData(loginPass: any) {
    const data = this.encrypt.encryptData(environment.TOKEN_LBL, loginPass);
    sessionStorage.setItem(environment.LOGINPASS_LBL, JSON.stringify(data));
  }

  getLoginPassData() {
    const data = sessionStorage.getItem(environment.LOGINPASS_LBL);
    return JSON.parse(this.encrypt.decryptData(data, environment.pssDpt));
  }
}
