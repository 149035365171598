import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.qa';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{
  private personnelPortalUrl = `${environment.personnelUrl}`;

  constructor(private router: Router, private toastr: ToastrService,
              private userService: UserService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    let usuario = this.userService.getSessionData();
    // Verificamos que exista
    if (usuario) {
      if (usuario.numUser) {
        return true;
      } else if(usuario.numUser !== '') {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('urlParam');
        sessionStorage.removeItem('loginPass');
        usuario = null;
        this.deleteCookies();
        this.toastr.info('No autorizado - Usuario invalido, serás redireccionado/a','',{
          progressBar: true,
          timeOut: 5000,
        }).onHidden.subscribe(() => {
          sessionStorage.removeItem('user');
          // Si cierra el mensaje o se terina el tiempo, redirecciona al portal
          window.location.href = this.personnelPortalUrl;
          return false;

        });
      }
    } else {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('urlParam');
      sessionStorage.removeItem('loginPass');
      this.deleteCookies();
      this.toastr.info('No autorizado - Sesión expirada, serás redireccionado/a','',{
        progressBar: true,
        timeOut: 5000,
      }).onHidden.subscribe(() => {
        // Si cierra el mensaje o se terina el tiempo, redirecciona al portal
        window.location.href = this.personnelPortalUrl;
        return false;
      });
    }
  }

  private deleteCookies(): void {
    const cookies = document.cookie.split(';');
    if (cookies.length === 0) {
      console.warn('No se borro la cookie por que no existe');
      return;
    }
    document.cookie = `ST=; max-age=0`;
    document.cookie = `HT=; max-age=0`;
    document.cookie = `PT=; max-age=0`;
    document.cookie = `ACC=; max-age=0`;
    document.cookie = `NUMUSER=; max-age=0`;
    document.cookie = `NAME=; max-age=0`;
  }
}
