import { Component, Directive, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.qa';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

interface App {
  icon: string;
  name: string;
  url: string;
  active: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public apps: Array<App>;
  public parametroUrl;
  public userLogged: any;
  private personnelPortalUrl = `${environment.redirectWebMsal}/`;


  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService
    ) {
    //nuevo login para giftcard, perfiles y tickets
    // http://localhost:4200/home?user=16006567&pass=U2FsdGVkX1%2BWN8D5G%2FHzDZEkjLushJqnG90De4SPXQw%3D
    //agregar token como parametro a las urls

    // const paramToGo = JSON.parse(sessionStorage.getItem('urlParam'));
    const paramToGo = this.userService.getUrlParamData();
    const user = this.userService.getSessionData();
    if (user !== undefined) {
      this.userLogged = user;
    } else {
      this.userLogged = null;
    }

    let countProfilePermissions = 0;
    if (this.userLogged.permissions) {
      this.userLogged.permissions.forEach(() => {
        countProfilePermissions++;
      });
    }
    
    if(paramToGo !== null && paramToGo !== undefined){
      this.apps = [];
      const appTicket = {
        // icon: 'summarize',
        icon: 'local_activity',
        name: 'Entradas',
        // url: 'http://localhost:4400',
        url: environment.ticketsUrl + `/landing/${paramToGo.version}`,
        // url: 'http://qa-esup-hrmg-bien-gye.web.app/',
        active: true,
      };
      const appGiftCard = {
        icon: 'card_giftcard',
        name: 'Gift Card',
        url: environment.giftcardUrl + `/landing/${paramToGo.version}`,
        active: true,
      };

      // appProfile = {
      const appProfile = {
        icon: 'account_box',
        name: 'Perfiles',
        // url: 'http://localhost:4300',
        // url: environment.profileUrl, //original
        url: environment.profileUrl + `/landing/${paramToGo.version}`,
        // url:  'http://qa-esup-hrmg-bien-gyu.web.app/',
        active: true,
      };

      this.apps.push(appTicket);
      //20211129 se saca giftcard temporalmente para produccion
      this.apps.push(appGiftCard);
      if (countProfilePermissions > 0) {
        this.apps.push(appProfile);
      }
    } else {
      //sin sesion correcta
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('urlParam');
      sessionStorage.removeItem('loginPass');
      sessionStorage.removeItem(environment.LOGINPASS_LBL);
      this.userService.deleteSessionData();
      
      document.cookie = `ST=; max-age=0`;
      document.cookie = `HT=; max-age=0`;
      document.cookie = `PT=; max-age=0`;
      document.cookie = `ACC=; max-age=0`;
      document.cookie = `NUMUSER=; max-age=0`;
      document.cookie = `NAME=; max-age=0`;

      this.toastr.info('No se ha podido obtener un usuario identificado, serás redireccionado(a).','',
          {
            progressBar: true,
            timeOut: 5000,
          }
        )
        .onHidden.subscribe(() => {
          // Si cierra el mensaje o se terina el tiempo, redirecciona al portal
          window.location.href = this.personnelPortalUrl;
        });
      
    }
  }

  ngOnInit(): void {
    // this.activatedRoute.paramMap.subscribe(params => {
    //   this.parametroUrl = params.get('home mark');
    //   console.log('home mark',this.parametroUrl);
    // });

    // PREVENT CONTEXT MENU FROM OPENING
    document.addEventListener("contextmenu", function(evt){
      evt.preventDefault();
    }, false);

    // PREVENT CLIPBOARD COPYING
    document.addEventListener("copy", function(evt){
      // Change the copied text if you want
      evt.clipboardData.setData("text/plain", "");

      // Prevent the default copy action
      evt.preventDefault();
    }, false);
  }

}

