import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.qa';
import { EncryptService } from './encrypt.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  // Endpoint from CommentType
  private apiUrl = `${environment.apiProfileEndPoint}requestLogin`;

  constructor(private http: HttpClient,private encrypt: EncryptService,
              private userService: UserService) {
  }

  // getUserDataLogin(userAndPass: string) {
  getUserDataLogin(credentials: any) {
    const opts = this.headerParams();
    const body: any = credentials;

    return this.http.post(this.apiUrl, body, opts).pipe(map((res:any) => {
      if(res.data 
      && res.data !== 'NO EXISTE|'
      && res.data !== 'CLAVE VENCIDA|'
      && res.data !== 'ACCESO DENEGADO|'
      && res.data !== 'NO EXISTE'
      && res.data !== 'CLAVE VENCIDA'
      && res.data !== 'ACCESO DENEGADO'
      && res.data !== 'BENEFICIO VIGENTE SOLO PARA COLABORADORES CON CONTRATO INDEFINIDO'
      ){
        res.data = JSON.parse(this.encrypt.decryptData(res.data, environment.pssDpt));
      }
      return res;
    }));

  }

  headerParams() {

    const httpOptions = {
      headers: {
        'Content-Type': 'application/json',
      }
    };

    return httpOptions;
  }
}
