import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ExitComponent } from './template/exit/exit.component';
import { LayoutComponent } from './template/layout/layout.component';

const routes: Routes = [
  { path: '', component: LoginComponent }, //original
  { path: 'login', component: LoginComponent },
  { path: 'login/:unavailable', component: LoginComponent },
  // Rutas de Módulos
  {
    path: 'home',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent, canActivate: [AuthGuardService] },
    ],
  },
  {
    // nuevo login
    path: 'home/:mark',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent, canActivate: [AuthGuardService] },
    ],
  },
  // Exit
  {
    path: 'exit',
    component: ExitComponent,
    children: [
      { path: '', component: LoginComponent, canActivate: [AuthGuardService] },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
