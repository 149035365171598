import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  constructor() { }

  public encryptData (data, encryptSecretKey) {
    let encrypted = CryptoJS.Rabbit.encrypt(data, encryptSecretKey).toString();
        let b64 = CryptoJS.enc.Base64.parse(encrypted);
        encrypted = b64.toString(CryptoJS.enc.Hex);
        return encrypted;
  }

  public decryptData (encryptedData, encryptSecretKey) {
    let data;
        try {
            let b64 = CryptoJS.enc.Hex.parse(encryptedData);
            let bytes = b64.toString(CryptoJS.enc.Base64);
            data = CryptoJS.Rabbit.decrypt(bytes, encryptSecretKey);
            data = data.toString(CryptoJS.enc.Utf8);
        } catch (e) {
          console.log("Error:",e);
          
        }
        return data;
  }
  // ORIGINALES
  // public encryptData(data, encryptSecretKey) {
  //   try {
  //     return CryptoJS.AES.encrypt(JSON.stringify(data), encryptSecretKey).toString(); //original
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  // public decryptData(data, encryptSecretKey) {
  //   try {
  //     const bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);
  //     if (bytes.toString()) {
  //       return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));//original
  //     }
  //     return data;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
}
