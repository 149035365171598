import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  loadingLogout = false;
  constructor(public matDialogRef: MatDialogRef<LogoutComponent>) {}

  ngOnInit(): void {}

  public onConfirm(): void {
    // Pasar un método por parámetro y ver como funciona
    this.matDialogRef.close('Confirm');
  }
  public onCancel(): void {
    this.matDialogRef.close('Cancel');
  }
}
