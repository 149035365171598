import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Modulo para tratar request Http
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// FIREBASE
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment.qa';
// Toast
import { ToastrModule } from 'ngx-toastr';
// Material
import { MaterialModule } from './material.module';
// Librería para trabajar con AD
// import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// Interceptors
import { ManageConnectionsService } from './interceptors/manage-connections.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { LayoutComponent } from './template/layout/layout.component';
import { LogoutComponent } from './template/logout/logout.component';
import { ExitComponent } from './template/exit/exit.component';
import { UserService } from './services/user.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LayoutComponent,
    LogoutComponent,
    ExitComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ToastrModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),  // imports firebase/app needed for everything
    AngularFirestoreModule,                                 // imports firebase/firestore, only needed for database features
    AngularFireAuthModule,                                  // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule,
    // MsalModule.forRoot(
    //   { // Librería para trabajar con AD
    //   auth: {
    //     clientId: 'c2a907b0-7d64-4caa-addf-6d849c8aca4c', // This is your client ID
    //     authority: 'https://login.microsoftonline.com/5542ea9b-1ba0-410c-8150-e0e9d9f1eee0', // This is your tenant ID
    //     redirectUri: environment.redirectWebMsal// This is your redirect URI
    //   },
    //   cache: {
    //     cacheLocation: 'localStorage',
    //     storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    //   },
    // }, {
    //   popUp: !isIE,
    //   consentScopes: [
    //     'user.read',
    //     'openid',
    //     'profile',
    //   ],
    //   unprotectedResources: [],
    //   protectedResourceMap: [
    //     ['https://graph.microsoft.com/v1.0/me', ['user.read']]
    //   ],
    //   extraQueryParameters: {}
    // }),
    /* Toast Message */
    ToastrModule.forRoot({
      // Global Options
      positionClass: 'toast-bottom-right',
      maxOpened: 5,
      newestOnTop: true,
      preventDuplicates: true,
      timeOut: 4000
    })
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageConnectionsService,
      multi: true
    },
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
