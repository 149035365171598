import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageConnectionsService implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private router: Router
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Genero un nuevo Request agregando información
    const newReq = req.clone();
    // newReq.headers.append('token','testtoken'); --Ya no va, sólo sirve para consultas a api
    // Envío el nuevo request con la información extra generada en el interceptor
    // Tambien se controla el error por conexión expirada desde el AD
    return next.handle(newReq).pipe(map(res =>  res), catchError(this.errorControl));
  }

  // Controla el error del AD
  private errorControl(error: HttpErrorResponse) {
    if (error.status === 401) {
      if (error.error.includes('Sesión expirada')) {
        sessionStorage.removeItem('user');
        this.toastr.error(error.error);
        this.router.navigate(['/']);
      }
    }
    return throwError(error);

  }
}
