<mat-sidenav-container class="sidenav-container">
  <!-- Menu Lateral Cuando es un mobile -->
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="'over'"
    [opened]="false"
  >
    <!-- Genero la misma toolbar -->
    <mat-toolbar color="primary">
      <div class="toolbar-title-giftcard">Portal de Recursos Humanos</div>
    </mat-toolbar>
    <!-- Menu Lateral Cuando es un mobile -->
    <!-- <img
      src="assets/img/falabellaLogo.png"
      class="mobile"
      alt="logo falabella"
    /> -->
    <!-- <mat-nav-list>
      <a mat-list-item [routerLink]="['/home']">Inicio</a>
    </mat-nav-list> -->
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="mat-elevation-z6">
      <!-- Botón hamburguesa cuando es mobile -->
      <!-- <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button> -->

      <!-- Menu desktop -->
      <!-- <div *ngIf="!(isHandset$ | async)">
        <img
          src="assets/img/falabellaLogo.png"
          alt="logo falabella"
          [routerLink]="['/home']"
        /> -->
      <!-- <button mat-button class="mleft" [routerLink]="['/home']">Inicio</button> -->
      <!-- </div> -->
      <div>
        <mat-toolbar color="primary">
          <div class="toolbar-title">Recursos Humanos</div>
        </mat-toolbar>
      </div>

      <div class="spacer"></div>
      <!-- Texto central -->
      <!-- <span class="welcome-text">Bienvenido(a) {{ userLogged.name }} </span> -->
      <div class="spacer"></div>
      <!-- Menu derecha -->
      <button
        mat-stroked-button
        [routerLink]="['/login']"
        *ngIf="userLogged === null || userLogged === undefined"
        class="mright"
      >
        <mat-icon>login</mat-icon> <span>Ingresar</span>
      </button>

      <button
        *ngIf="userLogged !== null || userLogged !== undefined"
        class="mright text-button"
        mat-icon-button
        (click)="logOut()"
      >
        <mat-icon>exit_to_app</mat-icon>
      </button>
      <!-- <mat-icon>exit_to_app</mat-icon> <span>Cerrar Sesión</span> -->

      <!-- <button mat-stroked-button [matMenuTriggerFor]="userMenu" class="mright" *ngIf="userLogged !== null"><mat-icon>account_circle</mat-icon> <span> {{userLogged.name}}</span></button>
            <mat-menu #userMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="logOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Desconectar</span>
                </button>
            </mat-menu> -->
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
